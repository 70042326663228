:root {
  @if variable-exists(colors) {
    @each $name, $color in $colors {
      @for $i from 0 through 5 {
        @if ($i == 0) {
          --#{$name}-50: #{tint($color, (5 - $i) * 19%)};
        } @else {
          --#{$name}-#{$i * 100}: #{tint($color, (5 - $i) * 19%)};
        }
      }

      @for $i from 1 through 4 {
        --#{$name}-#{($i + 5) * 100}: #{shade($color, $i * 15%)};
      }
    }
  }
}
