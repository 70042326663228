.p-progressbar {
  border: $progressBarBorder;
  height: $progressBarHeight;
  background: $progressBarBg;
  border-radius: $borderRadius;

  .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: $progressBarValueBg;
  }

  .p-progressbar-label {
    color: $textColor;
    line-height: $progressBarHeight;
  }
}
