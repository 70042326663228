@use 'sass:color';

$colors: (
  'blue': #2489a5,
  'orange': #d76014,
);

// Theme Specific Variables
$primaryColor: map-get($colors, 'orange');
$secondaryColor: #282828;
$primaryDarkColor: color.adjust($secondaryColor, $lightness: 10%);
$primaryDarkerColor: color.adjust($secondaryColor, $lightness: 10%);
$primaryTextColor: #ffffff;

$fontFamily: 'Myriad Pro', Helvetica, Arial, sans-serif;

// multiplies with all REM values, to scale the whole theme agaist changes in the base font size
// this is necassary because if fontsizes in shopify change, i'll affect the confuguratur UI
$remFactor: 0.588235294;

// Mandatory Designer Variables
@import '../common/variables/general';
@import '../common/variables/form';
@import '../common/variables/button';
@import '../common/variables/panel';
@import '../common/variables/_data';
@import '../common/variables/_overlay';
@import '../common/variables/_message';
@import '../common/variables/_menu';
@import '../common/variables/_media';
@import '../common/variables/_misc';

:root {
  --surface-a: #ffffff;
  --surface-b: #b2b2b2;
  --surface-c: #e6e6e6;
  --surface-d: #f6f6f6;
  --surface-e: #5d5d5e;
  --error-color: #a80000;
  --text-color: #{$textColor};
  --text-color-secondary: #{$textSecondaryColor};
  --primary-color: #{$primaryColor};
  --primary-color-hover: #{$primaryDarkColor};
  --primary-color-text: #{$primaryTextColor};
  --secondary-color: #{$secondaryColor};
  --font-family: #{$fontFamily};
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: #{$maskBg};
  --focus-ring: #{$focusShadow};
  --font-size-regular: 1.8rem * $remFactor;
  --font-size-small: 1.35rem * $remFactor;
  --font-size-big: 2.07rem * $remFactor;
  --rem-factor: #{$remFactor};
}
