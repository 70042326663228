@import './variables';
@import './_fonts';
@import '../../designer/_components';
@import '../common/extensions';

* {
  margin: 0;
  padding: 0;
}

.p-toast-message-icon {
  margin-right: 1.35rem * $remFactor;
}

button {
  cursor: pointer;
}
