@font-face {
  font-family: 'Zen Kaku Gothic Antique';
  src: url('./fonts/zenkakugothicantique-regular-webfont.woff2') format('woff2'),
    url('./fonts/zenkakugothicantique-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Zen Kaku Gothic Antique';
  src: url('./fonts/zenkakugothicantique-bold-webfont.woff2') format('woff2'),
    url('./fonts/zenkakugothicantique-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('./fonts/Myriad Pro Regular.woff2') format('woff2'),
    url('./fonts/Myriad Pro Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('./fonts/Myriad Pro Bold.woff2') format('woff2'),
    url('./fonts/Myriad Pro Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
